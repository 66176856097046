import React from 'react';

import asIcon from '../asIcon';

const LinkedInIconOriginal = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.5 27.7505C31.5 29.822 29.8215 31.5005 27.75 31.5005H8.25C6.17925 31.5005 4.5 29.822 4.5 27.7505V8.25049C4.5 6.17899 6.17925 4.50049 8.25 4.50049H27.75C29.8215 4.50049 31.5 6.17899 31.5 8.25049V27.7505Z"
      fill="#0288D1"
    />
    <path
      d="M9 14.2505H12.75V27.0005H9V14.2505ZM10.8637 12.7505H10.8427C9.72375 12.7505 9 11.9165 9 10.8747C9 9.81049 9.74625 9.00049 10.8855 9.00049C12.0262 9.00049 12.729 9.81049 12.75 10.8747C12.75 11.9157 12.0262 12.7505 10.8637 12.7505ZM27 27.0005H23.25V20.1762C23.25 18.5277 22.3313 17.4027 20.856 17.4027C19.7303 17.4027 19.1213 18.1617 18.8258 18.8952C18.7177 19.1577 18.75 19.8837 18.75 20.2505V27.0005H15V14.2505H18.75V16.2125C19.2907 15.3755 20.1375 14.2505 22.3035 14.2505C24.987 14.2505 26.9993 15.938 26.9993 19.706L27 27.0005Z"
      fill="white"
    />
  </svg>
);

export default asIcon(LinkedInIconOriginal);
