import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { createProps, getClass } from './Container';

const SIZE = ['xs', 'sm', 'md', 'lg', 'xl'];

const CLASS_MAP = {
  xs: 'col-xs',
  sm: 'col-sm',
  md: 'col-md',
  lg: 'col-lg',
  xl: 'col-xl',
  xsOffset: 'col-xs-offset',
  smOffset: 'col-sm-offset',
  mdOffset: 'col-md-offset',
  lgOffset: 'col-lg-offset',
  xlOffset: 'col-xl-offset',
};

type ColProps = {
  xs?: number | boolean;
  sm?: number | boolean;
  md?: number | boolean;
  lg?: number | boolean;
  xl?: number | boolean;
  xsOffset?: number;
  smOffset?: number;
  mdOffset?: number;
  lgOffset?: number;
  xlOffset?: number;
  first?: string;
  last?: string;
  className?: string;
  tagName?: string;
  children: React.ReactNode;
  vertical?: boolean;
};

const propTypes = {
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xlOffset: PropTypes.number,
  first: PropTypes.oneOf(SIZE),
  last: PropTypes.oneOf(SIZE),
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  vertical: PropTypes.bool,
};

const isInteger = (value: string | number) => {
  return typeof value === 'number' && Math.floor(value) === value;
};

const getColClassNames = (props: ColProps) => {
  const extraClasses = [];

  if (props.className) {
    extraClasses.push(props.className);
  }

  if (props.first) {
    extraClasses.push(getClass(`first-${props.first}`));
  }

  if (props.last) {
    extraClasses.push(getClass(`last-${props.last}`));
  }

  return Object.keys(props)
    .filter((key) => CLASS_MAP[key])
    .map((key) =>
      getClass(
        isInteger(props[key])
          ? `${CLASS_MAP[key]}-${props[key]}`
          : CLASS_MAP[key]
      )
    )
    .concat(extraClasses);
};

export const getColumnProps = (props: ColProps) =>
  createProps(propTypes, props, getColClassNames(props));

const Col = (props) => {
  const { tagName, ...columnProps } = props;
  return React.createElement(tagName || 'div', getColumnProps(columnProps));
};

Col.propTypes = propTypes;

Col.defaultProps = {
  xs: false,
  sm: true,
  md: false,
  lg: true,
  xl: false,
  xsOffset: 0,
  smOffset: 0,
  mdOffset: 0,
  lgOffset: 0,
  xlOffset: 0,
  first: null,
  last: null,
  className: '',
  tagName: '',
};

export default memo(Col);
