import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './Grid.module.scss';

type CreateProps = {
  [key: string]: any;
};

export const getClass = (className: string) => {
  return styles && styles[className] ? styles[className] : '';
};

export const createProps = (propTypes: CreateProps, props, classNames) => {
  const newProps = {};

  Object.keys(props)
    .filter((key) => key === 'children' || !propTypes[key])
    .forEach((key) => (newProps[key] = props[key]));

  const className = classNames.filter((cn) => cn).join(' ');
  return { ...{}, ...newProps, ...{ className } };
};

const containerPropTypes = {
  fluid: PropTypes.bool,
  className: PropTypes.string,
  tagName: PropTypes.string,
};

const Container = (props) => {
  const { fluid = false, className = '', tagName = '' } = props;
  const containerClass = getClass(fluid ? 'container-fluid' : 'container');
  const classNames = [className, containerClass];

  return React.createElement(
    tagName || 'div',
    createProps(containerPropTypes, props, classNames)
  );
};

export default memo(Container);
