import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const MobilePhoneIconLarge = ({ className, stroke, fill }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 3.5C4.5 1.84315 5.84315 0.5 7.5 0.5H15.5C17.1569 0.5 18.5 1.84315 18.5 3.5V20.5C18.5 22.1569 17.1569 23.5 15.5 23.5H7.5C5.84315 23.5 4.5 22.1569 4.5 20.5V3.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.5 17.5H18.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.5 20C11.2239 20 11 20.2239 11 20.5C11 20.7761 11.2239 21 11.5 21C11.7761 21 12 20.7761 12 20.5C12 20.2239 11.7761 20 11.5 20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default asIcon(MobilePhoneIconLarge);
