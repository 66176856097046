import { createSelector } from 'reselect';

import { statsSelector } from '../rootSelector';

import { initialState } from './slice';
import {
  AccessControlInfo,
  GeneralInfoConfig,
  GeneralStatsInitialState,
} from './type';

export const selectTotalAdCountByKind = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) => slicedState.count
);

export const selectFeatureFlags = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) => slicedState.featureFlags ?? {}
);

export const selectPricelist = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) => slicedState.pricelist
);

export const selectConfig = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) =>
    slicedState.config ?? ({} as GeneralInfoConfig)
);

export const selectPasswordRules = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) => slicedState?.passwordRules ?? []
);

export const selectIsCustomHeightTriggered = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) => slicedState.isCustomHeightTriggered
);

export const selectAccessControlInfo = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) =>
    slicedState.accessControlInfo ?? ({} as AccessControlInfo)
);

export const selectOutdatedBrowsers = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) =>
    slicedState.outdatedBrowsers ?? initialState.outdatedBrowsers
);

export const selectShowUpsellPromoAdInfoPageThreshold = createSelector(
  statsSelector,
  (slicedState: GeneralStatsInitialState) =>
    slicedState.config.showUpsellPromoAdInfoPageThreshold ??
    initialState.config.showUpsellPromoAdInfoPageThreshold
);
