import React, { ReactNode } from 'react';

import Link from '@components/controls/Link/Link';
import ButtonAsLink from '@components/controls/Button/ButtonAsLink';

import style from './FooterBox.module.scss';

type FooterLink = {
  name: ReactNode;
  href?: string;
  target?: string;
  rel?: string;
  label?: string;
  callback?: () => void;
};

type FooterBoxProps = {
  links: FooterLink[];
  title: string;
};

const FooterBox = ({ links, title }: FooterBoxProps) => {
  return (
    <>
      <h3 className={style.title}>{title}</h3>
      <div className={style.list}>
        {links.map((link: FooterLink, index: number) => {
          if (link.href) {
            return (
              <Link
                ariaLabel={
                  typeof link.name === 'string' ? link.name : link.label
                }
                key={index}
                href={link.href}
                target={link.target}
                rel={link.rel}
              >
                {link.name}
              </Link>
            );
          }

          if (link.callback) {
            return (
              <ButtonAsLink key={index} onClick={link.callback}>
                {link.name}
              </ButtonAsLink>
            );
          }

          return null;
        })}
      </div>
    </>
  );
};

export default FooterBox;
