import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const InfoIconMedium = ({ className, stroke, fill }: IconProps) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01886 16C12.161 16 15.5189 12.6421 15.5189 8.5C15.5189 4.35786 12.161 1 8.01886 1C3.87672 1 0.51886 4.35786 0.51886 8.5C0.51886 12.6421 3.87672 16 8.01886 16Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57143 12.0357H9.03572C9.03572 12.0357 8.50001 12.0917 8.50001 11.5V8.82143C8.50001 8.52556 8.57143 8 8.03571 8H7.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.26786 5.5C8.11992 5.5 8 5.61992 8 5.76786C8 5.91579 8.11992 6.03571 8.26786 6.03571C8.41579 6.03571 8.53571 5.91579 8.53571 5.76786C8.53571 5.61992 8.41579 5.5 8.26786 5.5V5.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default asIcon(InfoIconMedium);
