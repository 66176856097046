/* eslint-disable react/no-unused-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { createProps, getClass } from './Container';

const ROW_KEYS = [
  'start',
  'center',
  'end',
  'top',
  'middle',
  'bottom',
  'around',
  'between',
];

const SIZE = ['xs', 'sm', 'md', 'lg', 'xl'];

const propTypes = {
  reverse: PropTypes.bool,
  start: PropTypes.oneOf(SIZE),
  center: PropTypes.oneOf(SIZE),
  end: PropTypes.oneOf(SIZE),
  top: PropTypes.oneOf(SIZE),
  middle: PropTypes.oneOf(SIZE),
  bottom: PropTypes.oneOf(SIZE),
  around: PropTypes.oneOf(SIZE),
  between: PropTypes.oneOf(SIZE),
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  verticalGutter: PropTypes.number,
};

type RowProps = {
  reverse?: boolean;
  start?: string;
  center?: string;
  end?: string;
  top?: string;
  middle?: string;
  bottom?: string;
  around?: string;
  between?: string;
  className?: string;
  tagName?: string;
  children: React.ReactNode;
  verticalGutter?: number;
};

const getRowClassNames = (props: RowProps) => {
  const modificators = [props.className, getClass('row')];

  for (let i = 0; i < ROW_KEYS.length; i += 1) {
    const key = ROW_KEYS[i];
    const value = props[key];
    if (value) {
      modificators.push(getClass(`${key}-${value}`));
    }
  }

  if (props.reverse) {
    modificators.push(getClass('reverse'));
  }

  if (props.verticalGutter) {
    modificators.push(getClass(`verticalGutter${props.verticalGutter}`));
  }

  return modificators;
};

export const getRowProps = (props: RowProps) => {
  return createProps(propTypes, props, getRowClassNames(props));
};

const Row = (props) => {
  const { tagName = '' } = props;
  return React.createElement(tagName || 'div', getRowProps(props));
};

Row.propTypes = propTypes;

Row.defaultProps = {
  reverse: false,
  start: null,
  center: null,
  end: null,
  top: null,
  middle: null,
  bottom: null,
  around: null,
  between: null,
  className: '',
  tagName: '',
  verticalGutter: 0,
};

export default memo(Row);
