import React from 'react';

import asIcon from '../asIcon';

const FacebookIconOriginal = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.5 27.75C31.5 29.8215 29.8215 31.5 27.75 31.5H8.25C6.17925 31.5 4.5 29.8215 4.5 27.75V8.25C4.5 6.1785 6.17925 4.5 8.25 4.5H27.75C29.8215 4.5 31.5 6.1785 31.5 8.25V27.75Z"
      fill="#4267B2"
    />
    <path
      d="M25.776 18.75H23.25V28.5H19.5V18.75H17.25V15.75H19.5V13.9425C19.5015 11.3115 20.5942 9.75 23.694 9.75H26.25V12.75H24.5348C23.328 12.75 23.25 13.2 23.25 14.0422V15.75H26.25L25.776 18.75Z"
      fill="white"
    />
  </svg>
);

export default asIcon(FacebookIconOriginal);
