import {
  AboutUsRoute,
  AdPromoRoute,
  CourierTermsAndConditionsRoute,
  FairUsePolicyRoute,
  GoldAdRoute,
  HelpAndContactRoute,
  KPBlogBusinessHubRoute,
  KPBlogRoute,
  KPBlogSafeTradeRoute,
  KPBookBearRoute,
  KPCareersRoute,
  KpIzlogRoute,
  MediakitRoute,
  PrepaidPaymentRoute,
  PriceListRoute,
  PrivacyPolicyRoute,
  SellerRightsRoute,
  TermsAndConditionsRoute,
} from '@server/routes';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';

import { openFeedbackWithOption } from '@store/feedback/slice';
import { selectFeatureFlags } from '@store/generalStats/selector';

import { useAppSelector } from './redux/useAppSelector';

const useFooterLinks = (getKPMonogram, getHelpCenterLabel) => {
  const { isCourierOrderEnabled = false } = useAppSelector(selectFeatureFlags);
  const dispatch = useAppDispatch();
  const usefulLinks = [
    {
      name: 'O nama',
      href: AboutUsRoute.generateUrl(),
    },
    {
      name: 'Knjiga o KP',
      href: KPBookBearRoute.generateUrl(),
      target: '_blank',
    },
    {
      name: getKPMonogram('blog'),
      href: KPBlogRoute.generateUrl(),
      target: '_blank',
      rel: 'noopener',
      label: 'Kp blog',
    },
    {
      name: 'KP Karijere',
      href: KPCareersRoute.generateUrl(),
      target: '_blank',
    },
    {
      name: 'Saveti za bezbednost',
      href: KPBlogSafeTradeRoute.generateUrl(),
    },
    {
      name: getHelpCenterLabel('Pomoć i kontakt'),
      href: HelpAndContactRoute.generateUrl(),
      label: 'Pomoć i kontakt',
    },
    {
      name: 'Kontakt za medije',
      callback: () => dispatch(openFeedbackWithOption('media')),
    },
    {
      name: 'Copyright Infringement',
      callback: () => dispatch(openFeedbackWithOption('copyrightInfringement')),
    },
  ];

  const advertisingLinks = [
    {
      name: 'Kako da razvijem online prodaju',
      href: KPBlogBusinessHubRoute.generateUrl(),
      target: '_blank',
      label: 'Kako da razvijem online prodaju',
    },
    {
      name: 'Predračun za KP Kredit',
      href: PrepaidPaymentRoute.generateUrl({}, { method: 'invoice' }),
    },
    {
      name: 'Promocije oglasa',
      href: AdPromoRoute.generateUrl(),
    },
    {
      name: 'Zlatni Oglas',
      href: GoldAdRoute.generateUrl(),
      target: '_blank',
    },
    {
      name: 'Media kit',
      href: MediakitRoute.generateUrl(),
    },
    {
      name: getKPMonogram('izlog'),
      href: KpIzlogRoute.generateUrl(),
      label: 'Kp izlog',
    },
    {
      name: 'Cenovnik usluga',
      href: PriceListRoute.generateUrl(),
    },
  ];

  const securityLinks = [
    {
      name: 'Pravila i uslovi',
      href: TermsAndConditionsRoute.generateUrl(),
    },
    {
      name: 'Polisa o poštovanju privatnosti',
      href: PrivacyPolicyRoute.generateUrl(),
    },
    {
      name: 'Polisa o fer korišćenju',
      href: FairUsePolicyRoute.generateUrl(),
    },
    {
      name: 'Prava i obaveze prodavaca',
      href: SellerRightsRoute.generateUrl(),
    },
  ];

  if (isCourierOrderEnabled) {
    securityLinks.push({
      name: 'Pravila i uslovi zakazivanja kurira',
      href: CourierTermsAndConditionsRoute.generateUrl(),
    });
  }

  return { usefulLinks, advertisingLinks, securityLinks };
};

export default useFooterLinks;
