import React from 'react';

import asIcon from '../asIcon';

const InstagramIconOriginal = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5128 31.4926L10.5128 31.5068C7.21281 31.5098 4.51056 28.8128 4.50681 25.5128L4.49256 10.5128C4.48956 7.21281 7.18656 4.51056 10.4866 4.50681L25.4866 4.49256C28.7866 4.48956 31.4888 7.18656 31.4926 10.4866L31.5068 25.4866C31.5106 28.7873 28.8128 31.4896 25.5128 31.4926Z"
      fill="url(#paint0_radial)"
    />
    <path
      d="M25.5128 31.4926L10.5128 31.5068C7.21281 31.5098 4.51056 28.8128 4.50681 25.5128L4.49256 10.5128C4.48956 7.21281 7.18656 4.51056 10.4866 4.50681L25.4866 4.49256C28.7866 4.48956 31.4888 7.18656 31.4926 10.4866L31.5068 25.4866C31.5106 28.7873 28.8128 31.4896 25.5128 31.4926Z"
      fill="url(#paint1_radial)"
    />
    <path
      d="M18 23.25C15.1058 23.25 12.75 20.895 12.75 18C12.75 15.105 15.1058 12.75 18 12.75C20.8942 12.75 23.25 15.105 23.25 18C23.25 20.895 20.8942 23.25 18 23.25ZM18 14.25C15.9323 14.25 14.25 15.9323 14.25 18C14.25 20.0677 15.9323 21.75 18 21.75C20.0677 21.75 21.75 20.0677 21.75 18C21.75 15.9323 20.0677 14.25 18 14.25Z"
      fill="white"
    />
    <path
      d="M23.625 13.5C24.2463 13.5 24.75 12.9963 24.75 12.375C24.75 11.7537 24.2463 11.25 23.625 11.25C23.0037 11.25 22.5 11.7537 22.5 12.375C22.5 12.9963 23.0037 13.5 23.625 13.5Z"
      fill="white"
    />
    <path
      d="M22.5 27.75H13.5C10.6058 27.75 8.25 25.395 8.25 22.5V13.5C8.25 10.605 10.6058 8.25 13.5 8.25H22.5C25.3943 8.25 27.75 10.605 27.75 13.5V22.5C27.75 25.395 25.3943 27.75 22.5 27.75ZM13.5 9.75C11.4323 9.75 9.75 11.4323 9.75 13.5V22.5C9.75 24.5677 11.4323 26.25 13.5 26.25H22.5C24.5677 26.25 26.25 24.5677 26.25 22.5V13.5C26.25 11.4323 24.5677 9.75 22.5 9.75H13.5Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.5351 31.5263) scale(33.6743)"
      >
        <stop stopColor="#FFDD55" />
        <stop offset="0.328" stopColor="#FF543F" />
        <stop offset="0.348" stopColor="#FC5245" />
        <stop offset="0.504" stopColor="#E64771" />
        <stop offset="0.643" stopColor="#D53E91" />
        <stop offset="0.761" stopColor="#CC39A4" />
        <stop offset="0.841" stopColor="#C837AB" />
      </radialGradient>
      <radialGradient
        id="paint1_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.83956 4.15528) scale(22.3598 14.8983)"
      >
        <stop stopColor="#4168C9" />
        <stop offset="0.999" stopColor="#4168C9" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default asIcon(InstagramIconOriginal);
