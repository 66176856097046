import React from 'react';

import asIcon from '../asIcon';

const TikTokIconOriginal = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 27.75C31.5 29.8215 29.8215 31.5 27.75 31.5H8.25C6.17925 31.5 4.5 29.8215 4.5 27.75V8.25C4.5 6.1785 6.17925 4.5 8.25 4.5H27.75C29.8215 4.5 31.5 6.1785 31.5 8.25V27.75Z"
        fill="black"
      />
      <g clipPath="url(#clip0_4109_9)">
        <path
          d="M12.3377 20.9213C12.4553 19.9253 12.8533 19.3651 13.604 18.7959C14.6804 18.0222 16.0191 18.458 16.0191 18.458V15.8613C16.3447 15.8613 16.6704 15.8702 16.996 15.9235V19.2672C16.996 19.2672 15.6573 18.8315 14.5809 19.6052C13.8302 20.1743 13.4322 20.7345 13.3146 21.7305C13.3146 22.273 13.405 22.9755 13.8664 23.5892C13.7488 23.5269 13.6402 23.4646 13.5226 23.3846C12.5277 22.6999 12.3377 21.6594 12.3468 20.9302L12.3377 20.9213ZM22.5317 11.0591C21.799 10.2321 21.5186 9.38728 21.4101 8.80035H22.3417C22.3417 8.80035 22.1608 10.3477 23.5086 11.8595L23.5267 11.8773C23.1558 11.6461 22.8302 11.3615 22.5317 11.0502V11.0591ZM27.0091 13.4157V16.6883C27.0091 16.6883 25.8241 16.6438 24.9468 16.4126C23.7256 16.0925 22.9387 15.6034 22.9387 15.6034C22.9387 15.6034 22.396 15.2477 22.3508 15.2299V21.9884C22.3508 22.3619 22.2513 23.3046 21.9437 24.0871C21.5457 25.1098 20.9307 25.7857 20.8131 25.928C20.8131 25.928 20.0623 26.8439 18.7327 27.4486C17.5387 28 16.4895 27.9911 16.1729 28C16.1729 28 14.3638 28.0711 12.7267 26.9862C12.3739 26.7461 12.0392 26.4704 11.7407 26.177C13.3689 27.2708 15.187 27.1996 15.187 27.1996C15.5035 27.1908 16.5528 27.1996 17.7468 26.6483C19.0674 26.0347 19.8272 25.1276 19.8272 25.1276C19.9447 24.9942 20.5598 24.3184 20.9578 23.2868C21.2653 22.5042 21.3648 21.5616 21.3648 21.1881V14.4295L21.9528 14.803C21.9528 14.803 22.7397 15.2921 23.9608 15.6123C24.8382 15.8435 26.0231 15.8879 26.0231 15.8879V13.3268C26.4302 13.4157 26.7739 13.4424 27 13.4157H27.0091Z"
          fill="#EE1D52"
        />
        <path
          d="M26.0229 13.3179V15.8791C26.0229 15.8791 24.838 15.8346 23.9606 15.6034C22.7395 15.2832 21.9526 14.7941 21.9526 14.7941C21.9526 14.7941 21.4099 14.4384 21.3646 14.4206V21.1881C21.3646 21.5616 21.2651 22.5042 20.9576 23.2868C20.5596 24.3095 19.9445 24.9853 19.8269 25.1276C19.8269 25.1276 19.0762 26.0436 17.7465 26.6483C16.5526 27.1996 15.5033 27.1908 15.1867 27.1996C15.1867 27.1996 13.3777 27.2708 11.7405 26.1859C11.5687 26.008 11.4058 25.8301 11.2521 25.6345C10.7274 24.9853 10.4109 24.2116 10.3295 23.9982C10.2028 23.6158 9.93147 22.691 9.96765 21.8106C10.031 20.2454 10.5737 19.285 10.7094 19.0449C11.0802 18.3868 11.5777 17.7999 12.1566 17.3108C12.6722 16.8839 13.2511 16.546 13.8752 16.3148C14.5536 16.0391 15.2772 15.8879 16.0099 15.8791V18.4758C16.0099 18.4758 14.6712 18.04 13.5948 18.8137C12.844 19.3828 12.446 19.9431 12.3285 20.9391C12.3285 21.6683 12.5094 22.7088 13.5134 23.4024C13.631 23.4824 13.7486 23.5447 13.8571 23.6069C14.029 23.8381 14.246 24.0427 14.4903 24.2116C15.4671 24.8519 16.2903 24.8964 17.3395 24.4784C18.036 24.2028 18.5606 23.5803 18.8048 22.8866C18.9586 22.4509 18.9586 22.024 18.9586 21.5705V8.80035H21.4008C21.5003 9.38728 21.7807 10.2321 22.5224 11.0591C22.8209 11.3704 23.1556 11.655 23.5174 11.8862C23.6259 12.0018 24.1687 12.562 24.8832 12.9088C25.245 13.0867 25.634 13.229 26.0229 13.3179Z"
          fill="white"
        />
        <path
          d="M9.37109 23.2068L9.43441 23.3758C9.43441 23.3758 9.39823 23.2957 9.37109 23.2068Z"
          fill="#69C9D0"
        />
        <path
          d="M13.8847 16.3059C13.2605 16.546 12.6816 16.884 12.1661 17.3019C11.5872 17.791 11.0987 18.3869 10.7188 19.0449C10.5741 19.285 10.0404 20.2455 9.97712 21.8106C9.94094 22.6999 10.2123 23.6159 10.3389 23.9982C10.4203 24.2206 10.7369 24.9942 11.2615 25.6434C11.4153 25.8302 11.5781 26.008 11.75 26.1859C11.1982 25.8124 10.7098 25.3589 10.2847 24.8431C9.76908 24.2028 9.4525 23.438 9.37109 23.2068C9.24446 22.8155 8.96405 21.8995 9.00928 21.0103C9.0726 19.4451 9.61531 18.4847 9.75099 18.2446C10.1218 17.5865 10.6103 16.9996 11.1982 16.5016C11.7138 16.0747 12.2927 15.7368 12.9168 15.5056C13.3058 15.3455 13.7128 15.2299 14.1289 15.1499C14.753 15.0432 15.3952 15.0343 16.0284 15.1232V15.8702C15.2957 15.8791 14.563 16.0303 13.8937 16.3059H13.8847Z"
          fill="#69C9D0"
        />
        <path
          d="M21.4104 8.80036H18.9682V21.5527C18.9682 21.9973 18.9682 22.4331 18.8144 22.8688C18.5702 23.5536 18.0456 24.185 17.3491 24.4606C16.2999 24.8786 15.4767 24.8253 14.4999 24.1939C14.2556 24.0338 14.0476 23.8293 13.8667 23.598C14.6989 24.0338 15.4496 24.0249 16.3722 23.6603C17.0687 23.3846 17.5933 22.7621 17.8376 22.0685C17.9913 21.6327 17.9913 21.2059 17.9913 20.7523V8H21.3652C21.3652 8 21.32 8.32014 21.4104 8.80036ZM26.0235 12.6065V13.3179C25.6255 13.229 25.2456 13.0867 24.8838 12.9088C24.1783 12.562 23.6265 12.0018 23.518 11.8862C23.6446 11.9662 23.7712 12.0373 23.9069 12.1085C24.7662 12.5265 25.6164 12.6598 26.0235 12.6154V12.6065Z"
          fill="#69C9D0"
        />
      </g>
      <defs>
        <clipPath id="clip0_4109_9">
          <rect
            width="18"
            height="20"
            fill="white"
            transform="translate(9 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default asIcon(TikTokIconOriginal);
