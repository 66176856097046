import React, { memo } from 'react';
import Cookies from 'js-cookie';

import { COLOR_TYPES } from '@lib/constants/colors.constants';
import { COOKIE_URL } from '@lib/constants/env.constants';
import { getYear } from 'src/lib/getYear';

import {
  HomeRoute,
  KPAppleStoreRoute,
  KPFacebookRoute,
  KPInstagramRoute,
  KPLinkedInRoute,
  KPPlayStoreRoute,
  KPTikTokRoute,
} from '@server/routes';

import { useAppSelector } from '@hooks/redux/useAppSelector';
import useFooterLinks from '@hooks/useFooterLinks';

import { selectTotalAdCountByKind } from '@store/generalStats/selector';
import { selectIsMobileDevice } from '@store/meta/selector';

import FooterBox from '@layouts/desktop/FooterBox/FooterBox';
import { Col, Container, Row } from '@layouts/responsive/Grid';

import HelpCenterLabel from '@components/common/responsive/HelpCenterLabel/HelpCenterLabel';
import KpMonogram from '@components/common/responsive/KpMonogram/KpMonogram';
import AppleStoreLogo from '@components/common/responsive/Logo/AppleStoreLogo';
import GooglePlayLogo from '@components/common/responsive/Logo/GooglePlayLogo';
import Logo from '@components/common/responsive/Logo/Logo';
import ButtonAsLink from '@components/controls/Button/ButtonAsLink';
import Link from '@components/controls/Link/Link';
import MobilePhoneIconLarge from '@components/icons/categories/MobilePhoneIconLarge';
import FacebookIconOriginal from '@components/icons/social/FacebookIconOriginal';
import InstagramIconOriginal from '@components/icons/social/InstagramIconOriginal';
import LinkedInIconOriginal from '@components/icons/social/LinkedInIconOriginal';
import TikTokIconOriginal from '@components/icons/social/TikTokIconOriginal';

import style from './Footer.module.scss';

type FooterProps = {
  simple?: boolean;
};

const getKPMonogram = (suffix: string) => (
  <span>
    <KpMonogram /> {suffix}
  </span>
);

const getHelpCenterLabel = (prefix: string) => (
  <>
    {prefix} <HelpCenterLabel />
  </>
);

const Footer = ({ simple = false }: FooterProps) => {
  const isMobileDevice = useAppSelector(selectIsMobileDevice);
  const { totalActiveUser, totalAdCount } = useAppSelector(
    selectTotalAdCountByKind
  );

  const { usefulLinks, advertisingLinks, securityLinks } = useFooterLinks(
    getKPMonogram,
    getHelpCenterLabel
  );

  return (
    <footer className={style.footer}>
      <Container>
        {!simple && (
          <>
            <Row>
              <Col lgOffset={3} lg={10} className={style.footerInfo}>
                <div className={style.info}>
                  <Logo width="112px" height="47px" />
                  <div className={style.count}>
                    <p>{totalAdCount} oglasa </p>
                    <p>{totalActiveUser} korisnika</p>
                  </div>
                </div>
                <div className={style.footerSocialLogoHolder}>
                  <Link
                    ariaLabel="KupujemProdajem Facebook link"
                    href={KPFacebookRoute.generateUrl()}
                    target="_blank"
                    rel="noopener"
                  >
                    <FacebookIconOriginal />
                  </Link>
                  <Link
                    ariaLabel="KupujemProdajem Linkedin link"
                    href={KPLinkedInRoute.generateUrl()}
                    target="_blank"
                    rel="noopener"
                  >
                    <LinkedInIconOriginal />
                  </Link>
                  <Link
                    ariaLabel="KupujemProdajem Instagram link"
                    href={KPInstagramRoute.generateUrl()}
                    target="_blank"
                    rel="noopener"
                  >
                    <InstagramIconOriginal />
                  </Link>
                  <Link
                    ariaLabel="KupujemProdajem TikTok link"
                    href={KPTikTokRoute.generateUrl()}
                    target="_blank"
                    rel="noopener"
                  >
                    <TikTokIconOriginal />
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lgOffset={3} lg={3} className={style.infoHolder}>
                <FooterBox title="Korisno" links={usefulLinks} />
              </Col>

              <Col lg={3} className={style.infoHolder}>
                <FooterBox title="Reklamiranje" links={advertisingLinks} />
              </Col>

              <Col lg={3}>
                <FooterBox title="Pravila i uslovi" links={securityLinks} />
              </Col>
            </Row>

            <Row className={style.appStoreInfo}>
              <Link
                ariaLabel="KupujemProdajem PlayStore link"
                href={KPPlayStoreRoute.generateUrl()}
                rel="noopener"
                target="_blank"
              >
                <GooglePlayLogo />
              </Link>
              <Link
                ariaLabel="KupujemProdajem AppleStore link"
                href={KPAppleStoreRoute.generateUrl()}
                rel="noopener"
                target="_blank"
              >
                <AppleStoreLogo />
              </Link>
            </Row>
            {isMobileDevice && (
              <Row className={style.mobileVersionHolder}>
                <ButtonAsLink
                  onClick={async () => {
                    try {
                      await Cookies.remove('desktop_requested', {
                        path: '/',
                        domain: COOKIE_URL,
                      });
                      window.location.href = HomeRoute.generateUrl();
                    } catch (error) {
                      // console.log(error);
                    }
                  }}
                >
                  <MobilePhoneIconLarge
                    stroke={COLOR_TYPES.contentInteractiveSecondary}
                  />
                  Vratite se na mobilni sajt
                </ButtonAsLink>
              </Row>
            )}
          </>
        )}

        <Row className={style.footerBottom}>
          <Col>
            <p>{`KupujemProdajem © ${getYear()} `}</p>
            <p>by Quable B.V. The Netherlands. </p>
            <p>All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default memo(Footer);
