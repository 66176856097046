import { COLOR_TYPES } from '@lib/constants/colors.constants';

import InfoIconMedium from '../../../icons/info/InfoIconMedium';

import style from './HelpCenterLabel.module.scss';

const HelpCenterLabel = () => {
  return (
    <div className={style.labelHolder}>
      <InfoIconMedium stroke={COLOR_TYPES.contentOnFill} />
    </div>
  );
};

export default HelpCenterLabel;
