import React from 'react';

import style from './KpMonogram.module.scss';

const KpMonogram = () => {
  return (
    <>
      <span className={style.letterK}>k</span>
      <span className={style.letterP}>p</span>
    </>
  );
};

export default KpMonogram;
